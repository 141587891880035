import "./header.css";

function Header() {
	return (
		<div id="header" className="container header-container">
			<p>
				I<span>'</span>m
			</p>
			<h3>Robin O'List</h3>
			<h2>Front-End Web Developer & eCommerce Professional</h2>
		</div>
	);
}
export default Header;
