import React, { useState } from "react";
import "./portfolio.css";

const portfolioItems = [
	{
		id: 11,
		brandCode: "mmlm",
		brandName: "Mr. Men Little Miss",
		brandDescription:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dignissim commodo enim, et ultrices nunc porta eu. Sed vestibulum cursus odio, et lobortis ante cursus fringilla. Pellentesque elit massa, placerat sed ex eu, aliquet venenatis elit. Donec vehicula sapien purus, et sodales sem venenatis eu.",
	},
	{
		id: 22,
		brandCode: "wodw",
		brandName: "World of David Walliams",
	},
	{
		id: 20,
		brandCode: "snow",
		brandName: "The Snowman",
	},
	{
		id: 13,
		brandCode: "mon",
		brandName: "Monopoly",
	},
	{
		id: 1,
		brandCode: "bean",
		brandName: "Mr. Bean",
	},
	{
		id: 15,
		brandCode: "peppa",
		brandName: "Peppa Pig",
	},
	{
		id: 6,
		brandCode: "gruff",
		brandName: "Gruffalo",
	},
	{
		id: 4,
		brandCode: "drwho",
		brandName: "Doctor Who",
	},
	{
		id: 2,
		brandCode: "clang",
		brandName: "Clangers",
	},
	{
		id: 5,
		brandCode: "dta",
		brandName: "Dave Thompson Art",
	},
	{
		id: 7,
		brandCode: "isadora",
		brandName: "Isadora Moon",
	},
	{
		id: 8,
		brandCode: "lyl",
		brandName: "Love your Location",
	},
	{
		id: 9,
		brandCode: "masha",
		brandName: "Masha and the Bear",
	},
	{
		id: 10,
		brandCode: "miffy",
		brandName: "Miffy",
	},
	{
		id: 12,
		brandCode: "milo",
		brandName: "Uh Oh Milo!",
	},
	{
		id: 14,
		brandCode: "moonbug",
		brandName: "Moonbug",
	},
	{
		id: 16,
		brandCode: "pip",
		brandName: "Pip and Posy",
	},
	{
		id: 17,
		brandCode: "rwp",
		brandName: "Railway Posters",
	},
	{
		id: 18,
		brandCode: "sand",
		brandName: "Sarah & Duck",
	},
	{
		id: 19,
		brandCode: "sicat",
		brandName: "Simon's Cat",
	},
	{
		id: 21,
		brandCode: "tomg",
		brandName: "Tom Gates",
	},
	{
		id: 3,
		brandCode: "dino",
		brandName: "Dinosaur Roar",
	},
];

function Portfolio() {
	const [targetIndex, setTargetIndex] = useState(1);
	const [portfolioModalOpen, setPortfolioModalOpen] = useState(false);

	const togglePortfolioModal = (e) => {
		//setPortfolioModalOpen(!portfolioModalOpen);
		setPortfolioModalOpen(false);
		setTargetIndex(e.currentTarget.dataset.index);
	};

	return (
		<div id="portfolio" className="container portfolio-container">
			<h2>Portfolio</h2>
			<p>Below is a list of websites that I have built and/or managed over the past 3 years. Tap or hover to see a rolling full screen.</p>
			<div className="portfolio-container__inner">
				{portfolioItems.map((item, index) => (
					<div
						key={item.id}
						style={{
							backgroundImage: `url("images/${item.brandCode}_full_800.jpg")`,
						}}
						className="portfolio__item"
						onClick={togglePortfolioModal}
						data-index={`${index}`}
					>
						<span className="portfolio__item_name">
							{item.brandName}
						</span>
					</div>
				))}
			</div>
			{portfolioModalOpen && (
				<div className="modal_overlay" onClick={togglePortfolioModal}>
					<div
						className="portfolio__item_content"
						data-item={portfolioItems[targetIndex].brandCode}
            onClick={e => {
              e.stopPropagation();
            }}
					>
						<div className="portfolio__item_content__inner">
							<span onClick={togglePortfolioModal}>X</span>
							<h2>{portfolioItems[targetIndex].brandName}</h2>
							<p>{portfolioItems[targetIndex].brandDescription}</p>              
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export default Portfolio;

/*
https://shop.worldofdavidwalliams.com/
https://shop.clangers.com/
https://davethompsonart.co.uk/
https://shop.doctorwho.tv/
https://gruffaloshop.com/
https://isadoramoon.shop/
https://www.loveyourlocation.co.uk/
https://shop.mashabear.com/
https://miffyshop.co.uk/
https://moonbug.shop/
https://shop.mrbean.com/
https://mypeppapigshop.co.uk/
https://pipandposyshop.com/
https://railwayposters.co.uk/
https://shop.sarahandduck.com/
https://personalisedmonopoly.com/
https://shop.dinosaurroar.com/
https://mrmen.com/
https://shop.simonscat.com/
https://shop.thebrilliantworldoftomgates.com/
https://shop.thesnowman.com/
https://uhohmilo.com/
  */
