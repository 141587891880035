import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { TiGroupOutline } from "react-icons/ti";
import { BiMessageRoundedDots } from "react-icons/bi";

function Navbar() {
  return (
    <div className="navigation">
      <a href="#home" title="Home">
        <AiOutlineHome className="icon active-nav" />
      </a>
      <a href="#about" title="About">
        <AiOutlineUser className="icon" />
      </a>
      <a href="#portfolio" title="Portfolio">
        <TiGroupOutline className="icon" />
      </a>
      <a href="#contact" title="Contact">
        <BiMessageRoundedDots className="icon" />
      </a>
    </div>
  );
}

export default Navbar;
