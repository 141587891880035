import "./home.css";
import img from "../props/robin.jpg";
import htmlImage from "../props/html_240x.png";
import cssImage from "../props/css_240x.png";
import laravelImage from "../props/laravel_240x.png";
import jsImage from "../props/js_240x.png";
import reactImage from "../props/react_240x.png";
import phpImage from "../props/php_240x.png";


import Buttons from "../button/button";


function Home() {
	return (
		<div id="home" className="container home-container">
			<div className="profile">
				<img src={img} alt="" className="profile__img"/>
			</div>
			<div className="skills">
				<span><img src={htmlImage} alt="HTML 5" title="HTML 5" /></span>
        		<span><img src={cssImage} alt="CSS 3" title="CSS 3" /></span>
        		<span><img src={reactImage} alt="ReactJS" title="ReactJS" /></span>
        		<span><img src={jsImage} alt="JavaScript" title="JavaScript" /></span>
        		<span><img src={laravelImage} alt="Laravel" title="Laravel" /></span>
				<span><img src={phpImage} alt="PHP" title="PHP" /></span>
			</div>


			<div id="about">
				<h2>About Me</h2>
				<p>
					I have been working with eCommerce brands for over 15 years
					both developing successful digital marketing campaigns and
					high-quality converting websites.
				</p>
				<p>
					My passion is technology, and I am always pushing the
					boundary to get the very best out of it. Regardless of
					budget, there is always a solution to achieve success in
					eCommerce using technology.
				</p>
				<p>
					I am a highly skilled digital professional with over 15
					years' experience in a broad range of commercial roles with
					a specific expertise in digital marketing and
					website/software development. In addition to successfully
					leading teams to deliver ambitious results in a pressurised
					environment, I can juggle multiple tasks whilst maintaining
					meticulous attention to detail and possess excellent
					strategic planning and project management skills.
				</p>
			</div>

			<Buttons />
		</div>
	);
}

export default Home;
