import "./contact.css";
import {
	AiOutlineWhatsApp,
	AiOutlineMail,
	AiOutlineLinkedin,
} from "react-icons/ai";

function Contact() {
	return (
		<div id="contact" className="container contact-container">
			<h2>Contact Me</h2>
			<div className="contact-links">
				<div className="contact">
					<AiOutlineMail className="icon" />
					<h2>
						Email <span>robin@olist.co.uk</span>
					</h2>
				</div>
				<div className="contact">
					<AiOutlineWhatsApp className="icon" />
					<h2>
						WhatsApp <span>+44 7557 521 045</span>
					</h2>
				</div>

				<div className="contact">
					<AiOutlineLinkedin className="icon" />
					<h2>
						LinkedIn <span>linkedin.com/in/robolist</span>
					</h2>
				</div>
			</div>
		</div>
	);
}

export default Contact;
