import Header from "./components/header/header";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
import Navbar from "./components/nav/nav";
import Footer from "./components/footer/footer";
import Portfolio from "./components/portfolio/portfolio";

function App() {
  return (
    <>
      <Header />
      <Navbar />
      <Home />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}
export default App;